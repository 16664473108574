import { Component, OnInit, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { WebserviceService } from '../api/webservice.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  email: any = '';
  password: any = '';
  visible:boolean = true;
  changetype:boolean =true;

  constructor(public webservice: WebserviceService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
  }
  onLogin() {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (this.email === '') {
      this.toastr.error('Please enter email');
    } else if (!re.test(this.email)) {
      this.toastr.error('Please enter valid email');
    } else if (this.password === '') {
      this.toastr.error('Please enter password');
    } else {
      var params = {
        email: this.email,
        password: this.password,
        device_token: 'this.deviceToken', // removed quotes
        device_id: 'this.deviceId', // removed quotes
        device_type: 'A'
      };
  
      this.spinner.show();
  
      this.webservice.postData('userLogin', params).then((result: any) => {
        console.log(result);
        if (result.status === 1) {
          // Assuming user_type values are checked against specific messages
          switch (result.data.user_type) {
            case 0:
              this.toastr.error('Admin is not allowed to login; only property managers are allowed.');
              break;
            case 2:
              this.toastr.error('Residents are not allowed to login; only property managers are allowed.');
              break;
            // case 3:
            //   this.toastr.error('Staffs are not allowed to login; only property managers are allowed.');
            //   break;
            case 5:
              this.toastr.error('Vendors are not allowed to login; only property managers are allowed.');
              break;
            default:
              this.toastr.success('Login successful');
              localStorage.setItem('LoginToken', JSON.stringify(result.data));
              this.router.navigateByUrl('/home');
              break;
          }
        } else {
          this.toastr.error(result.msg);
        }
        this.spinner.hide();
      }).catch((err) => {
        this.spinner.hide();
        console.error('Error occurred during login:', err);
      });
    }
  }
  // onLogin() {
  //   var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //   if (this.email == '') {
  //     this.toastr.error('Please enter email');
  //   } else if (!re.test(this.email)) {
  //     this.toastr.error('Please enter valid email');
  //   } else if (this.password == '') {
  //     this.toastr.error('Please enter password');
  //   } else {
  //     var params = {
  //       email: this.email,
  //       password: this.password,
  //       device_token: 'this.deviceToken',
  //       device_id: 'this.deviceId',
  //       device_type: 'A'
  //     }
  //     this.spinner.show();
  //     this.webservice.postData('userLogin', params).then((result: any) => {
  //       console.log(result)
  //       if(result.status == 1) {
  //         console.log(result.data.user_type)
  //         if(result.data.user_type == 0 || result.data.user_type == 2 || result.data.user_type == 3 || result.data.user_type == 4) {
  //           this.toastr.error('Password does not match with given email');
  //         } else {
  //           this.toastr.success("Login successful");
  //           localStorage.setItem('LoginToken', JSON.stringify(result.data));
  //           this.router.navigateByUrl('/home');
  //         }
  //       } else {
  //         this.toastr.error(result.msg);
  //       }
  //       this.spinner.hide();

  //     }, (err) => {
  //       this.spinner.hide();
  //       console.log(err);
  //     });

  //   }
  // }
  // userLogin
  // localStorage.setItem('LoginToken', JSON.stringify(this.data.data));

  // this.webservice.postData('propertyManagerLogin', params).then((result) => {

  // }, (err) => {
  //   console.log(err);
  // });
  viewpass(){
    this.visible = !this.visible;
    this.changetype = !this.changetype;
  }
  @HostListener('window:keydown.enter', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.email !== '' && this.password !== '') {
      this.onLogin();
    }
  }

}
